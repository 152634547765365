import Vue from 'vue'
import BtnWithLoading from './btn-with-loading'
import InputSms from './input-sms'
import InputCaptcha from './input-captcha'
import PassEditor from './pass-editor'

Vue.component('BtnWithLoading', BtnWithLoading)
Vue.component('InputSms', InputSms)
Vue.component('InputCaptcha', InputCaptcha)
Vue.component('PassEditor', PassEditor)

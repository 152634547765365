import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: () => import('../views/home.vue')
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/consent',
    name: 'Consent',
    component: () => import('../views/consent.vue')
  },
  {
    path: '/changepwd',
    name: 'ChangePwd',
    component: () => import('../views/change-pwd.vue')
  },
  {
    path: '/verifypwd',
    name: 'VerifyPwd',
    component: () => import('../views/verify-pwd.vue')
  },
  {
    path: '/bindphone',
    name: 'BindPhone',
    component: () => import('../views/bind-phone.vue')
  },
  {
    path: '/findpwd',
    name: 'FindPwd',
    component: () => import('../views/find-pwd.vue')
  },
  {
    path: '/verifyphone',
    name: 'VerifyPhone',
    component: () => import('../views/verify-phone.vue')
  },
  {
    path: '/verifysms',
    name: 'VerifySms',
    component: () => import('../views/verify-sms.vue')
  },
  {
    path: '/resetpwd',
    name: 'ResetPwd',
    component: () => import('../views/reset-pwd.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_URL || '/',
  routes
})

export { routes }
export default router

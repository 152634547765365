import get from 'lodash.get'
import JSEncrypt from 'jsencrypt'
import { sm2 } from 'miniprogram-sm-crypto'

function hexToBase64(str) {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, '')
        .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
        .replace(/ +$/, '')
        .split(' ')
    )
  )
}

const rsaEncryptor = new JSEncrypt()
const sm2Encryptor = (() => {
  let publicKey = ''
  let cipherMode = 1
  return {
    setPublicKey(key) {
      publicKey = key
    },
    encrypt(str) {
      return hexToBase64('04' + sm2.doEncrypt(str, publicKey, cipherMode))
    }
  }
})()

export const encrypters = {
  rsa: rsaEncryptor,
  sm2: sm2Encryptor
}

export default { get, encrypters }

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import utils from './utils'
import './components'

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$utils = utils
Vue.prototype.$notice = text => {
  let noticeWrap = document.getElementById('global-notice')
  let noticeEl = document.getElementById('notice')
  noticeEl.innerText = text
  noticeWrap.style.opacity = 1

  setTimeout(() => {
    noticeWrap.style.opacity = 0
  }, 4000)
}

new Vue({
  router,
  store,
  render: h => h(App),
  // for prerender
  mounted: () => document.dispatchEvent(new Event('render-event'))
}).$mount('#app')

<template>
  <div v-if="store.captcha.id" class="log_ui logPass">
    <div class="dsib log_tool_outer vm" style="display: flex">
      <label
        class="txt_default"
        for="upass"
        id="label_upass"
        default_txt="请输入图形验证码"
      ></label>
      <input
        class="txt"
        type="hidden"
        id="captchaInpId"
        name="captchaId"
        :value="store.captcha.id"
      />
      <input
        class="txt"
        type="text"
        id="captchaInp"
        name="captchaValue"
        placeholder="输入图形验证码"
        tabindex="3"
        style="width:60%"
        required
        :value="store.captchaValue"
        @input="updateStore('captchaValue', $event.target.value)"
      />
      <img
        alt="验证码"
        id="imgCaptcha"
        :src="store.captcha.pic"
        style="width: 120px;height:40px;margin-left: 10px; cursor: pointer;"
        @click="freshCaptcha"
      />
      <img
        alt="换一张"
        id="imgCaptchaRefresh"
        src="../assets/images/refresh.png"
        style="height:18px;align-self:center;margin-top:-5px;margin-left: 1px; cursor: pointer;"
        @click="freshCaptcha"
      />
    </div>
  </div>
</template>
<script>
import updateStore from '@/mixins/update-store'

export default {
  mixins: [updateStore],

  methods: {
    freshCaptcha() {
      this.$api
        .freshCaptcha()
        .then(res => {
          res = res.data || {}
          if (res.errcode !== '0') {
            this.updateStore('errmsg', res.errmsg)
            return
          }

          this.updateStore('captcha', res.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

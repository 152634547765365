<template>
  <form
    id="login-form"
    action="./login"
    method="POST"
    @submit.prevent="handleSubmit"
  >
    <div class="top_header dsn">
      <div class="header_middle_con_area">
        <a class="dsib header_logo"></a>
      </div>
    </div>
    <div id="allContainerHelper">
      <div id="wrap">
        <div class="contanier">
          <div class="log_area">
            <div class="left_log_area">
              <div class="log_sel_items">
                <div class="dsib log_item jobnumber_log active">
                  <i class="ui_icon icon_oa_logo"></i>
                </div>
              </div>
              <div class="sepLine dsn">
                <div class="curPosMarker"></div>
              </div>
              <div id="error_promp"></div>
              <!-- include login method -->
              <div class="log_main" v-if="loginComp">
                <div class="log_title" v-if="defaultLoginType === 1">
                  <p>腾讯里约认证登录</p>
                </div>
                <div class="log-tab" v-if="defaultLoginType === 2">
                  <div
                    :class="{ 'log-tab-item': true, active: v === loginType }"
                    v-for="(v, k) in loginTypes"
                    :key="k"
                    @click="changeLoginType(v)"
                  >
                    {{ k }}
                  </div>
                </div>
                <component :is="loginComp" />
                <InputCaptcha />
                <div class="tips_ui clearfix">
                  <!--<span class="tips_ui_left">-->
                  <!--<label for="auto_login">-->
                  <!--<input id="auto_login" name="auto_login" type="checkbox" value="" />下次自动登录-->
                  <!--</label>-->
                  <!--</span>-->
                  <span
                    v-if="loginType !== 1"
                    class="tips_ui_right"
                    style="font-size: 13px; margin-top: -11px;padding-right: 3px"
                  >
                    <a href="./findpwd">找回密码</a>
                  </span>
                </div>
                <div class="append_op dsib">
                  <div class="dsib autoLogSet" id="errmsg">
                    {{ errmsg }}
                  </div>
                </div>
                <div class="logBtnOuter">
                  <BtnWithLoading
                    type="submit"
                    class="btn"
                    :loading="isPending"
                  >
                    登录
                  </BtnWithLoading>
                </div>
                <div class="newVerProBottom"></div>
              </div>
              <div
                v-else
                style="font-size: x-large; padding: 20px; margin-top: -160px"
              >
                <p>指定的登录方式尚未支持</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import LoginWithPwd from '@/components/login-with-pwd'
import LoginWithSms from '@/components/login-with-sms'
import LoginWithFactor from '@/components/login-with-factor'
import mapState from '@/mixins/map-state'
import updateStore from '@/mixins/update-store'
import handleErr from '@/mixins/handle-err'

export default {
  mixins: [mapState, updateStore, handleErr],
  components: {
    LoginWithPwd,
    LoginWithSms,
    LoginWithFactor
  },
  data() {
    return {
      // challenge 存在有效期，登录页不使用 store 缓存的 challenge
      loginChallenge: '',
      isPending: false,
      defaultLoginType: Number(window.config.loginType) || 0,
      needCaptchaOnPassword: false,
      loginTypes: {
        帐号密码登录: 0,
        短信验证码登录: 1
      }
    }
  },
  computed: {
    loginComp() {
      switch (this.loginType) {
        case 0:
          return 'LoginWithPwd'
        case 1:
          return 'LoginWithSms'
        case 3:
          return 'LoginWithFactor'
        default:
          return 'LoginWithPwd'
      }
    }
  },
  created() {
    this.getChallenge()
    this.prepareLogin()
  },
  methods: {
    getChallenge() {
      if (this.$route.query && this.$route.query.login_challenge) {
        this.loginChallenge = this.$route.query.login_challenge
        this.updateStore('challenge', this.$route.query.login_challenge)
        this.$router.replace({ path: this.$route.path })
      }
    },
    prepareLogin() {
      this.prepareStore(this.loginChallenge)
        .then(() => {
          if (this.captcha.id) {
            this.needCaptchaOnPassword = true
          }
          try {
            this.updateStore('encrypter', this.$utils.encrypters[this.em])
            this.encrypter.setPublicKey(atob(this.pk))
          } catch (err) {
            console.log(err)
            this.updateStore('errmsg', err.message)
          }
        })
        .catch(err => {
          this.updateStore('errmsg', err.message)
        })
    },
    freshCaptcha() {
      this.$api
        .freshCaptcha()
        .then(res => {
          res = res.data || {}
          if (res.errcode !== '0') {
            this.updateStore('errmsg', res.errmsg)
            return
          }

          this.updateStore('captcha', res.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleSubmit(e) {
      console.log('submit', e)
      let payload = {
        _csrf: window.sessionStorage.getItem('csrf'),
        challenge: this.loginChallenge || this.challenge,
        LoginType: this.loginType
      }

      if (this.captcha.id) {
        payload.captchaId = this.captcha.id
        payload.captchaValue = this.captchaValue
      }

      payload['account_name'] =
        this.account && this.encrypter.encrypt(this.account)
      payload['password'] =
        this.password && this.encrypter.encrypt(this.password)
      payload['smsCode'] = this.sms && this.encrypter.encrypt(this.sms)

      this.isPending = true
      this.Error = ''
      this.$api
        .login(payload)
        .then(res => {
          this.isPending = false
          console.log(res)
          res.data = res.data || {}
          if (res.data.errcode !== '0' && res.data.errmsg) {
            this.updateStore('errmsg', res.data.errmsg)
            this.updateStore('captchaValue', '')
          }

          if (res.data.data && res.data.data.location) {
            sessionStorage.setItem('current_pwd', payload['password'])
          }

          const data = (res.data && res.data.data) || {}
          ;['Challenge', 'PK', 'Captcha', 'CSRF'].forEach(key => {
            data[key] && this.updateStore(key.toLowerCase(), data[key])
          })
        })
        .catch(err => {
          this.handleErr(err)
        })
    },
    changeLoginType(loginType) {
      if (loginType === this.loginType) {
        return
      }

      this.updateStore('password', '')
      this.updateStore('errmsg', '')
      this.updateStore('loginType', loginType)

      // 短信默认不显示图形验证码，发送时如果需要再弹出。
      if (loginType == 1) {
        this.updateStore('captcha', {})
        return
      }

      if (this.needCaptchaOnPassword) {
        this.updateStore('captchaValue', '')
        this.freshCaptcha()
      } else {
        this.updateStore('captcha', {})
      }
    }
  }
}
</script>
<style lang="less" scoped>
.log-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: -20px;
}
.log-tab-item {
  cursor: pointer;
  flex: 1;
  height: 42px;
  line-height: 42px;
  border-bottom: 2px solid transparent;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  &.active {
    border-bottom: 2px #009ac3 solid;
  }
}
</style>

<template>
  <button :type="type" :disabled="loading" @click="onClick">
    <template v-if="loading">
      <img class="loading-icon" src="../assets/images/loading.svg" />
      <span>{{ loadingText }}</span>
    </template>
    <slot v-else name="default" />
  </button>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: '请稍候...'
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
<style lang="less" scoped>
.loading-icon {
  height: 15px;
  animation: spin 2s linear infinite;
  margin-right: 5px;
  &,
  & + span {
    vertical-align: middle;
  }
}

button:disabled {
  cursor: not-allowed !important;
  background: #efefef;
}
</style>

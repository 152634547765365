<template>
  <!-- 验证码 -->
  <div class="log_ui logPass">
    <span class="dsib vm" style="display:none;">验证码:</span>
    <div class="dsib log_tool_outer vm">
      <input
        class="txt userPass sms_code"
        type="text"
        placeholder="输入短信验证码"
        tabindex="2"
        required
        :value="store[field]"
        @input="updateStore(field, $event.target.value)"
      />
      <BtnWithLoading
        v-if="!isSent"
        id="btn_sendSMS"
        :loading="isPending"
        :onClick="sendSMS"
        loadingText=""
      >
        获取短信验证码
      </BtnWithLoading>
      <button v-else id="btn_sendSMS" disabled>
        重新获取 {{ remaining }}s
      </button>
    </div>
  </div>
</template>
<script>
import BtnWithLoading from '@/components/btn-with-loading'
import updateStore from '@/mixins/update-store'

export default {
  mixins: [updateStore],
  props: {
    field: {
      type: String,
      default: 'sms'
    },
    phone: {
      type: String,
      default: ''
    },
    directly: {
      type: Boolean,
      default: false
    },
    isCounting: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BtnWithLoading
  },
  data() {
    return {
      interval: null,
      isPending: false,
      isSent: false,
      remaining: 60
    }
  },
  beforeDestroy() {
    this.interval && clearInterval(this.interval)
  },
  mounted() {
    if (this.isCounting && this.remaining > 0) {
      this.countdown(this.remaining)
    }
  },
  methods: {
    sendSMS() {
      const account = this.directly
        ? this.phone || this.store.phone
        : this.store.account
      const errmsg = this.directly
        ? '请输入手机号'
        : this.field === 'sms'
        ? '请输入帐号名'
        : '请输入帐号名或手机号码'
      if (!account) {
        this.updateStore('errmsg', errmsg)
        return
      }

      // 两步验证只允许通过帐号名获取短信，输入手机号提示错误
      if (
        !this.directly &&
        this.field === 'sms' &&
        /^1[34578]\d{9}$/.test(account)
      ) {
        this.updateStore('errmsg', '请输入帐号名而非手机号')
        return
      }

      if (this.store.captcha.id && !this.store.captchaValue) {
        this.updateStore('errmsg', '请先输入图形验证码')
        return
      }

      this.updateStore('errmsg', '')
      this.isPending = true
      this.$api
        .sendSMS(
          account,
          {
            captchaId: this.store.captcha.id,
            captchaValue: this.store.captchaValue
          },
          this.directly
        )
        .then(res => {
          console.log(res)
          res = res.data || {}
          this.isPending = false

          this.updateStore(
            'captcha',
            res.data.Captcha || { id: '', pic: '', value: '' }
          )

          if (res.data.result === 0) {
            this.$notice(
              res.data.msg
                ? res.data.msg
                : res.data.phone
                ? `验证码已发送至${res.data.phone}，请留意短信`
                : '验证码已发送，请留意短信'
            )
            this.countdown(res.data.remaining)
            this.updateStore('errmsg', '')
          } else if (res.data.result === 1) {
            // 手机号未绑定，仅限管理员帐号
            this.$router.push({
              path: '/verifypwd'
            })
          } else if (res.data.result === 2) {
            this.updateStore(
              'errmsg',
              res.errmsg || '验证码发送错误，可能需要重新发送'
            )
          } else if (res.data.result === 3) {
            this.countdown(res.data.remaining)
            this.updateStore('errmsg', '')
          } else {
            this.updateStore('errmsg', res.errmsg || '验证码发送错误')
          }

          if (res.data.Captcha) {
            this.updateStore('captchaValue', '')
          }
        })
        .catch(err => {
          this.isPending = false
          console.log(err)
          this.updateStore('errmsg', err.message || '验证码发送失败')
        })
    },
    countdown(remaining) {
      this.isSent = true
      this.remaining = remaining

      this.interval = setInterval(() => {
        this.remaining--

        if (this.remaining <= 0) {
          clearInterval(this.interval)
          this.isSent = false
        }
      }, 1000)
    }
  }
}
</script>

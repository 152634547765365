<template>
  <div>
    <!-- 用户名 -->
    <div class="log_ui logTool">
      <div class="dsib log_tool_outer vm">
        <input
          class="txt username vm"
          type="text"
          id="userNameInp"
          placeholder="输入帐号名/手机号码"
          tabindex="1"
          :value="store.account"
          @input="updateStore('account', $event.target.value)"
          required
        />
      </div>
    </div>
    <InputSms field="password" />
  </div>
</template>
<script>
import InputSms from '@/components/input-sms'
import updateStore from '@/mixins/update-store'

export default {
  mixins: [updateStore],
  components: {
    InputSms
  }
}
</script>

<template>
  <div>
    <!-- 用户名 -->
    <div class="log_ui logTool">
      <div class="dsib log_tool_outer vm">
        <input
          class="txt username vm"
          type="text"
          id="userNameInp"
          placeholder="输入帐号"
          tabindex="1"
          required
          :value="store.account"
          @input="updateStore('account', $event.target.value)"
        />
      </div>
    </div>
    <!-- 密码 -->
    <div class="log_ui logPass">
      <span class="dsib vm" style="display:none;">密码:</span>
      <div class="dsib log_tool_outer vm">
        <label
          class="txt_default"
          for="upass"
          default_txt="请输入您的密码"
        ></label>
        <input
          class="txt userPass"
          type="password"
          placeholder="输入密码"
          tabindex="2"
          required
          :value="store.password"
          @input="updateStore('password', $event.target.value)"
        />
      </div>
    </div>
    <InputSms field="sms" />
  </div>
</template>
<script>
import updateStore from '@/mixins/update-store'

export default {
  mixins: [updateStore]
}
</script>

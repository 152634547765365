import Vue from 'vue'
import Vuex from 'vuex'
import { encrypters } from '../utils'

Vue.use(Vuex)

let loginType = Number(window.config.loginType) || 0
// 2 表示开启多种登录方式: 0 或 1
if (loginType === 2) {
  loginType = 0
}

let encrypter = {}

try {
  encrypter = encrypters[sessionStorage.getItem('em') || 'rsa']
  encrypter.setPublicKey(atob(sessionStorage.getItem('pk')) || '')
} catch (err) {
  console.log(err, encrypter)
}

const store = new Vuex.Store({
  state: {
    loginType: loginType,
    challenge: sessionStorage.getItem('challenge') || '',
    csrf: sessionStorage.getItem('csrf') || '',
    pk: sessionStorage.getItem('pk') || '',
    em: sessionStorage.getItem('em') || 'rsa',
    encrypter: encrypter,
    captcha: {
      id: '',
      pic: ''
    },
    errmsg: '',
    account: sessionStorage.getItem('account') || '',
    password: '',
    sms: '',
    captchaValue: '',
    phone: sessionStorage.getItem('phone') || '',
    phoneMask: sessionStorage.getItem('phoneMask') || '',
    phoneCipher: sessionStorage.getItem('phoneCipher') || '',
    appId: sessionStorage.getItem('appId') || '',
    areaCode: sessionStorage.getItem('areaCode') || '',
    resetPwdCode: sessionStorage.getItem('resetPwdCode') || ''
  },
  mutations: {
    updateStore(state, { key, value }) {
      console.log('updateStore', key, value)

      if (
        [
          'challenge',
          'csrf',
          'pk',
          'em',
          'account',
          'phone',
          'phoneMask',
          'phoneCipher',
          'appId',
          'areaCode',
          'resetPwdCode'
        ].indexOf(key) >= 0
      ) {
        sessionStorage.setItem(key, value || '')
      }
      state[key] = value
    }
  }
})

export default store

import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      store: state => state
    })
  },
  methods: {
    updateStore(key, value) {
      this.$store.commit('updateStore', { key, value })
    },
    prepareStore(challenge) {
      return this.$api
        .prepareLogin(challenge || this.store.challenge)
        .then(res => {
          const data = (res.data && res.data.data) || {}

          if (data['LoginType'] !== undefined) {
            this.updateStore('loginType', data['LoginType'])
          }

          ;['Challenge', 'PK', 'Captcha', 'CSRF', 'EM'].forEach(key => {
            data[key] && this.updateStore(key.toLowerCase(), data[key])
          })
        })
        .catch(err => {
          console.log(err)
          // this.updateStore('errmsg', err.message)
        })
    }
  }
}

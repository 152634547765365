import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      loginType: state => state.loginType,
      challenge: state => state.challenge,
      pk: state => state.pk,
      em: state => state.em,
      csrf: state => state.csrf,

      account: state => state.account,
      password: state => state.password,
      sms: state => state.sms,
      captcha: state => state.captcha,
      captchaValue: state => state.captchaValue,

      errmsg: state => state.errmsg,
      encrypter: state => state.encrypter,

      phone: state => state.phone,
      phoneMask: state => state.phoneMask,
      areaCode: state => state.areaCode,
      appId: state => state.appId,
      phoneCipher: state => state.phoneCipher
    })
  }
}

const axios = require('axios')
const qs = require('qs')

const request = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL || '/',
  timeout: 15000
})

request.interceptors.response.use(
  function(response) {
    if (response.data && response.data.data && response.data.data.location) {
      console.log('redirect:', response)
      let location = response.data.data.location
      if (location.indexOf('/oauth2') > 0) {
        sessionStorage.clear()
      }

      window.location.href = location
    }

    return response
  },
  function(error) {
    return Promise.reject(error)
  }
)

export const prepareLogin = challenge => {
  return request.get('api/login/prepare?login_challenge=' + challenge)
}

export const login = payload => {
  return request.post('login', qs.stringify(payload))
}

export const prepareConsent = challenge => {
  return request.get('api/consent/prepare?consent_challenge=' + challenge)
}

export const consent = payload => {
  return request.post(
    'consent',
    qs.stringify(payload, {
      arrayFormat: 'repeat'
    })
  )
}

export const freshCaptcha = () => {
  return request.get('api/captcha/generate')
}

export const sendSMS = (account, payload, directly = false) => {
  try {
    account = btoa(account)
  } catch (err) {
    return Promise.reject(err)
  }

  return request.post(
    `api/accounts/sendsms?r=${account}${directly ? '&m=1' : ''}`,
    qs.stringify(payload)
  )
}

export const verifypwd = payload => {
  let accountB64
  try {
    accountB64 = btoa(payload.account)
  } catch (err) {
    return Promise.reject(err)
  }

  return request.post(
    `verifypwd?u=${accountB64}&c=${payload.challenge}`,
    qs.stringify(payload)
  )
}

export const getPwdRules = () => {
  return request.get('api/accounts/password/properties/get')
}

export const changepwd = payload => {
  return request.post('changepwd', qs.stringify(payload))
}

export const bindphone = payload => {
  return request.post('bindphone', qs.stringify(payload))
}

export const findpwd = payload => {
  return request.post('findpwd', qs.stringify(payload))
}

export const verifyphone = payload => {
  return request.post('verifyphone', qs.stringify(payload))
}

export const verifysms = payload => {
  return request.post('verifysms', qs.stringify(payload))
}

export const resetpwd = payload => {
  return request.post('resetpwd', qs.stringify(payload))
}

export default {
  prepareLogin,
  login,
  prepareConsent,
  consent,
  freshCaptcha,
  sendSMS,
  verifypwd,
  getPwdRules,
  changepwd,
  bindphone,
  findpwd,
  verifyphone,
  verifysms,
  resetpwd
}

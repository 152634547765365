export default {
  methods: {
    handleErr(err) {
      this.isPending = false
      console.log(err, err.response)

      if (err.response && err.response.status === 500) {
        this.updateStore('errmsg', '页面已过期，刷新页面再试试吧。')
        sessionStorage.clear()
        return
      }

      if (
        err.response &&
        err.response.status === 400 &&
        err.response.data.indexOf('CSRF') >= 0
      ) {
        if (!this.__retried) {
          this.__retried = true
          this.isPending = true
          this.prepareStore().then(() => this.handleSubmit())
        } else {
          window.location.href = '/'
        }
        return
      }

      this.updateStore('errmsg', err.message)
    }
  }
}

<template>
  <div>
    <div class="log_ui logPass">
      <input type="password" style="display:none;" />
      <div class="dsib log_tool_outer vm">
        <input
          class="txt userPass"
          type="password"
          id="new_pwd"
          placeholder="输入新密码"
          v-model="newPass"
          @input="validatePass"
          @input.once="isPassEdit = true"
          autocomplete="new-password"
          required
        />
      </div>
    </div>
    <div class="log_ui logPass">
      <div class="dsib log_tool_outer vm">
        <input
          class="txt userPass"
          type="password"
          id="repeat_new_pwd"
          placeholder="确认新密码"
          v-model="repeatNewPass"
          autocomplete="new-password"
          @input="validatePass2"
          @input.once="isRepeatPassEdit = true"
          required
        />
      </div>
    </div>
    <div class="log_ui logPass">
      <p id="ruleNote">
        密码至少包含英文、数字或符号中的{{
          strengthRule[1].split(',')[0]
        }}种，且不少于{{ strengthRule[1].split(',')[1] }}位
      </p>

      <div :class="['strengthBar', 'strength-level-' + strengthLevel]">
        <div class="strength"></div>
        <div class="strength"></div>
        <div class="strength"></div>
      </div>

      <div class="append_op dsib custom" v-if="isPassEdit || isRepeatPassEdit">
        <div
          class="dsib autoLogSet"
          style="color: red;padding-left: 20px;margin-top: 5px"
        >
          {{ warnText || store.errmsg }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import updateStore from '@/mixins/update-store'

export default {
  mixins: [updateStore],
  data() {
    return {
      isPassEdit: false,
      isRepeatPassEdit: false,
      newPass: '',
      repeatNewPass: '',
      warnText: '',

      complexRule: ['2', '8', '3'],
      complexMessage: [
        '密码至少包含英文、数字或符号中的2种',
        '密码长度不允许少于8位',
        '密码不允许连续3个以上的字母/数字'
      ],
      strengthRule: ['2,8', '2,8', '3,8'],
      strengthMessage: [
        '密码强度较低:2种组合以下或位数低于8位',
        '密码强度中等:2种组合且位数大于等于8位',
        '密码强度较高:3种组合且位数大于等于8位'
      ]
    }
  },
  computed: {
    isValid() {
      return this.validatePass() && this.validatePass2()
    },
    strengthLevel() {
      let value = this.newPass
      let modes = 0
      let level = 0
      // 正则表达式验证符合要求的
      // if (value.length < 8) return '低'
      if (/\d/.test(value)) modes++ // 数字
      if (/[a-zA-Z]/.test(value)) modes++ // 大小写
      if (/[~!@#$%^&*()_+`{}[\]|\\:"<>?,./]/.test(value)) modes++ // 特殊字符
      if (
        modes === Number(this.strengthRule[2].split(',')[0]) &&
        value.length >= Number(this.strengthRule[2].split(',')[1])
      ) {
        console.log('密码强度高')
        level = 2
      } else if (
        modes === Number(this.strengthRule[1].split(',')[0]) &&
        value.length >= Number(this.strengthRule[1].split(',')[1])
      ) {
        console.log('密码强度中')
        level = 1
      } else {
        console.log('密码强度低')
        level = 0
      }

      // if (Number(this.complexRule[2]) > 0) {
      //   if (this.repeatWord(value).length >= Number(this.complexRule[2])) {
      //     // console.log('密码强度低：连续重复')
      //     level = 0
      //   }
      // }

      return level
    }
  },
  mounted() {
    this.pageName = this.$route.query.pageName || '修改密码'
    this.$api.getPwdRules().then(res => {
      if (res.data.errcode === '0') {
        this.complexRule = res.data.data[0].value
        this.complexMessage = res.data.data[0].message
        this.strengthRule = res.data.data[3].value
        this.strengthMessage = res.data.data[3].message
      }
    })
  },
  methods: {
    showWarning(text) {
      this.warnText = text
    },
    repeatWord(str) {
      str += ''
      let match = str.match(/(\w)\1+/g)
      if (match) {
        return match.sort((x, y) => y.length - x.length)[0]
      }

      return ''
    },
    validatePass() {
      let value = this.newPass
      if (!value) {
        this.showWarning('新密码不能为空')
        return false
      }

      if (value === this.account) {
        this.showWarning('密码不允许与帐号相同')
        return false
      }

      if (/[^~!@#$%^&*()_+`{}[\]|\\:"<>?,./\da-zA-Z]/.test(value)) {
        this.showWarning('密码含有非法字符')
        return false
      }

      if (Number(this.complexRule[2]) > 0) {
        if (this.repeatWord(value).length >= Number(this.complexRule[2])) {
          this.showWarning(this.complexMessage[2])
          return false
        }
      }

      let modes = 0
      // 正则表达式验证符合要求的
      // if (value.length < 8) return '低'
      if (/\d/.test(value)) modes++ // 数字
      if (/[a-zA-Z]/.test(value)) modes++ // 大小写
      if (/[~!@#$%^&*()_+`{}[\]|\\:"<>?,./]/.test(value)) modes++ // 特殊字符
      if (
        modes < Number(this.complexRule[0]) ||
        value.length < Number(this.complexRule[1])
      ) {
        this.showWarning('新密码强度不足')
        return false
      }

      this.showWarning('')
      return true
    },
    validatePass2() {
      if (this.validatePass()) {
        if (this.newPass === this.repeatNewPass) {
          this.showWarning('')
          return true
        }

        this.isRepeatPassEdit && this.showWarning('两次输入密码不相同')
      }

      return false
    }
  }
}
</script>

<style lang="less" scoped>
.strength-level-0 {
  .strength:first-child {
    background: #e65525 !important;
  }
}
.strength-level-1 {
  .strength:not(:last-child) {
    background: yellow !important;
  }
}
.strength-level-2 {
  .strength {
    background: green !important;
  }
}
</style>

<template>
  <div id="app">
    <div class="layout">
      <GlobalHeader />
      <router-view />
      <GlobalFooter />
    </div>
  </div>
</template>

<script>
import GlobalHeader from '@/components/global-header'
import GlobalFooter from '@/components/global-footer'

export default {
  components: {
    GlobalHeader,
    GlobalFooter
  }
}
</script>

<style lang="less">
@import './assets/styles/common.css';
@import './assets/styles/rio.css';
</style>
